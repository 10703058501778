                                              /*    fonts    */
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');


:root{
  --dark-grey: #333333;
  --white: #fff;
  --black: #000;
  --green:  #7fb401;
  --grey: #666;
  --icon: #f5f5f5;
  --light-grey: #ccc;
  --primary-color: #2c604a;
  --before-color:#f7f052;
  --global-body: #f1f3f5;
  --border-color: #e3e3e3;
  --sec2-color: #fbfafa;
  --font-family: 'Roboto Condensed', sans-serif;
  --font-family1: 'Saira Condensed', sans-serif;
}
*{
  box-sizing: border-box;
  font-family: var(--text);
  padding: 0;
  margin: 0;
}
.navbar a
{
  font-size: 1rem;
}
.navbar
{
  background-color: var(--white) !important;
  margin-top: 2%;
  padding-top: 2%;
}
.navbar-brand
{
  font-size: 1.9rem !important;
}
.menu_active
{
  font-weight: bold;
  border-bottom: 1px solid var(--black);
}
.nav ul li NavLink
{
  background-color: var(--black) !important;
}

.dropdown
{
  position: relative;
  display: inline-block;
}
.dropdown-menu
{
  position: absolute;
  display: none;
  border: none !important;
  width: 250px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
}
.dropdown-menu li{
  text-decoration: none;
  display: block;
  /* padding: 12px 16px; */
} 
.sub-main
{
  padding: 10px 15px !important;
  /* overflow: hidden; */
}
.dropdown-item:hover{
  background-color: var(--primary-color) !important;
  color: var(--white) !important;
  visibility: visible;
}
.dropdown-item
{
  padding: 15px 20px !important;
  font-size: 1rem;
  display: inline-block;
  vertical-align: top;
  letter-spacing: 0.2rem;
  font-weight: 600;
  font-family: var(--text);
  position: relative;
  line-height: normal;
  border: none;
  text-decoration: none;
  top: 0;
  transform: translate(0);
  transition: all .5s ease-in-out;
  border-radius: 0px;
  width: 155%;
}
.dropdown-item::before
{
  content: "";
  background-color:  var(--before-color);
  width: 2%;
  height: 0%;
  padding: 0;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  margin-left: 5px;
 
  /* transition: all .8s cubic-bezier(.23,1,.32,1); */
}
.dropdown-item:hover::before
{
  width: 2%;
  height: 70%;
  text-align: center;
  transition: all .5s ease-in-out;
  /* border-radius: 3%; */
}
.dropdown:hover .dropdown-menu
{
  display: block;
}
#main-header
{
  position: relative;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  z-index: 999;
  transition: all .3s ease-out 0s;
  border-bottom: 2px solid var(--global-body);
}
header#main-header
{
  background-color: var(--white);
  position: relative;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  z-index: 999;
  transition: all .3s ease-out 0s;
  
}
.icon
{
  background-color: var(--icon);
  color: var(--primary-color) !important;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
}
ul li{
  list-style: inherit;
}
.cont{
  color: var(--primary-color);
}
h6
{
  font-size: 1rem;
  line-height: 1.75;
  letter-spacing: .125rem;
  font-weight: 600;
  display: block;
  /* margin: 0px 5px 8px; */
}
.css_prefix-email, .css_prefix-address, .cont
{
  text-align: left;
  margin-left: 20px;
  margin-top: 15px;
  display: flex;
}

.css_prefix-button, .link.css_prefix-button
{
  padding: 20px 25px;
  font-size: 1rem;
  color: var(--white);
  display: inline-block;
  vertical-align: top;
  background-color: var(--primary-color);
  letter-spacing: 0.2rem;
  font-weight: 600;
  font-family: var(--text);
  position: relative;
  line-height: normal;
  border: none;
  text-decoration: none;
  top: 0;
  right: 0;
  transform: translate(0);
  transition: all .5s ease-in-out;
  border-radius: 0px;
}

.css_prefix-button::before
{
  content: "";
  background-color:  var(--before-color);
  width: 5%;
  height: 100%;
  padding: 0;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  transition: all .8s cubic-bezier(.23,1,.32,1);
}
.link.css_prefix-button:hover::before
{
  width: 100%;
  height: 10%;
  text-align: center;
  border-radius: 3%;
}
.link:hover
{
  background-color: var(--white);
  color: var(--primary-color);
}
.text-right
{
  text-align: right !important;
}
.navbar
{
  margin-top: 2%;
  padding-top: 2%;
}
.css_prefix nav#site-navigation
{
  justify-content: space-between;
}
#navlink
{

  padding-right: 2%;
}
.nav-link:hover
{
  color: var(--primary-color) !important;
}
.css_prefix nav#site-navigation
{
  justify-content: space-between;
}
.farmin
{
  position: absolute;
  background-color: var(--white);
  top: 90%;
  right: 0;
  width: 500px;
  height: 300px;
  padding: 3%;
  margin-bottom: 10%;
}
strong
{
  color: var(--primary-color);
}
.css_prefix-button1, .link1.css_prefix-button1
{
  padding: 10px 30px;
  font-size: 1rem;
  color: var(--white);
  display: inline-block;
  vertical-align: top;
  background-color: var(--primary-color);
  letter-spacing: 0.5rem;
  font-weight: 600;
  font-family: var(--font-family1);
  position: absolute;
  line-height: normal;
  border: none;
  text-decoration: none;
  top: 350px;
  left: 794px;
  transform: translate(0);
  transition: all .5s ease-in-out;
  border-radius: 0px;
}
.css_prefix-button1::before
{
  content: "";
  background-color:  var(--before-color);
  width: 3%;
  height: 100%;
  padding: 0;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  transition: all .8s cubic-bezier(.23,1,.32,1);
}
.link1.css_prefix-button1:hover::before
{
  width: 100%;
  height: 5%;
  text-align: center;
  border-radius: 3%;
}
.link1:hover
{
  background-color: var(--white);
  color: var(--primary-color);
}
h1{
  font-family: var(--font-family1);
  font-size: 4.5rem;
  font-weight: 600;
}
.backimg
{
  background-image: url('../src/images/banner-1.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 280px 0px 230px 0px;
}
.cont1
{
  color: var(--white);
  /* display: block; */
  font-size: 3.157rem;
  line-height: 1.3;
  letter-spacing: .125rem;
  font-weight: 600;
}
.cont2
{
  color: var(--before-color);
  font-size: 3.157rem;
  line-height: 1.3;
  letter-spacing: .125rem;
  font-weight: 600;
}
.cont h2
{
  font-family: "Saira Condensed",sans-serif;
  line-height: 1.3;
  font-size: 5.157rem;
  letter-spacing: .125rem;
  font-weight: 600;
}
.sec1_2
{
  padding-left: 5%;
  padding-top: 4%;
  align-items: center;
  flex-wrap: nowrap;
  display: flex;
}
.icon1
{
  background-color: var(--white);
  padding: 2%;
}
.cont_icn
{
  font-family: "Saira Condensed",sans-serif;
  color: var(--white);
  font-weight: 600;
  padding-left: 2%;
  font-size: 1.88rem;
  line-height: 1.3;
  letter-spacing: .125rem;
  margin: 0px;
  white-space: nowrap;
}
.dis
{
  justify-content: space-between;
  padding-left: 2%;
}
.row
{
  --bs-gutter-x: -0.5rem !important;
  --bs-gutter-y: 0 !important;
}
.sec2_mon
{
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75rem;
  letter-spacing: .125rem;
  margin: 0;
  padding-bottom: 25%;
  border-bottom: 2px solid var(--before-color);
  word-break: keep-all;
  font-family: "Saira Condensed",sans-serif;
}
.sec2_num
{
  font-family: "Saira Condensed",sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75rem;
  letter-spacing: .125rem;
  margin: 0;
  padding-top: 25%;
  color: var(--black);
}
.sec2
{
  position: relative;
}
.sec2_1
{
  padding-top: 5%;
  border-right: 2px solid var(--border-color); 
}
/* .sec2_2
{
  padding: 5%;
  background-color: var(--sec2-color);
  text-align: center;
  margin: 2%;
  height: 3%;
} */
.sec2_tit
{
  padding-bottom: 5%;
  margin: 0%;
  font-size: 1.777rem;
  line-height: 1.3;
  letter-spacing: .123rem;
  font-weight: 600;
  font-family: "Saira Condensed",sans-serif;
  font-stretch: condensed;
  clear: both;
  word-break: break-word;
   
}
.sec2_cont p
{
  color: var(--grey);
  font-size: 1rem;
  line-height: 1.75;
  font-family: "Roboto",sans-serif;
  font-weight: 400;
  text-align: left;
}
.sec2_cont
{
  padding: 2%;
}
.sec2_pad
{
  padding: 6%;
}
.sec2_btn
{
margin-top: 4%;
vertical-align: middle;
padding: 4%;
}
.sec2_1:hover 
{
  background-color: var(--primary-color);
  color: var(--white);
  padding-top: 5%;
  transition: 1s;
  transition-timing-function: ease-out;
  border-radius: 15px 0px;
}
.sec2_1:hover .sec2_cont p
{
  color: var(--white);
}
.sec2_2:hover
{
  background-color: var(--white);
  color: var(--white);
}
.sec2_1:hover .text_btn
{
  color: var(--white);
}
/* .posi
{
  position: relative;
} */
.sec2_icn 
{
  position: absolute;
  top: 58%;
  left: 28.5%;
  transform: translate(-163%, -50%);
  background-color: var(--primary-color);
  border-radius: 50%;
  width: 3%;
  height: 9%;
  text-align: center;
  fill: #f7f052;
}
.sec2_icn2 
{
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--primary-color);
  border-radius: 50%;
  width: 3%;
  height: 9%;
  text-align: center;
  fill: #f7f052;
}
.sec2_icn3 
{
  position: absolute;
  top: 58%;
  left: 75%;
  transform: translate(-50%, -50%);
  background-color: var(--primary-color);
  border-radius: 50%;
  width: 3%;
  height: 9%;
  text-align: center;
  fill: #f7f052;
}
.sec2_1:hover .sec2_icn
{
  background-color: var(--before-color);
}
.sec2_1:hover .sec2_icn svg
{
  fill: #2c604a;
}
.sec3_2
{
  position: relative;
}
.sec3
{
  padding-top: 10%;
  background-color: var(--sec2-color);
  /* padding-left: 5%; */
  height: 666px;
}
.sec3_2
{
  font-size: 3.175rem;
  line-height: 1.3;
  letter-spacing: .125rem;
  font-weight: 600;
  font-family: "Saira Condensed",sans-serif;
  font-stretch: condensed;
  word-break: brek-word;
}
.sec3_ul ul
{
  list-style: none;
  padding: 0px 0px 0px 0px;
}
.sec3_1
{
  margin-left: 5%;
}
.sec3_p1
{
  color: var(--grey);
  font-size: 1rem;
  line-height: 1.75;
  font-family: "Roboto",sans-serif;
  font-weight: 400;
}
.sec3_li1
{
  text-align: start;
  display: flex;
}
.sec3_li1 p
{
  padding-left: 1%;
  line-height: 0.75;
  color: var(--grey);
  font-size: 1rem;
  font-family: "Roboto",sans-serif;
  font-weight: 400;
}
.sec3_3
{
  position: relative;
}
.sec3_btn2
{
  padding: 10px 30px;
  font-size: 1rem;
  display: inline-block;
  vertical-align: top;
  background-color: var(--primary-color);
  color: var(--white);
  letter-spacing: 0.5rem;
  font-weight: 600;
  font-family: var(--font-family1);
  line-height: normal;
  border: none;
  transform: translate(0);
  transition: all .5s ease-in-out;
  border-radius: 0px;
}
.sec3_btn2::before
{
  content: "";
  background-color: var(--before-color);
  width: 3%;
  height: 100%;
  padding: 0;
  z-index: -1;
  top: 0;
  transform: translateY(0, 0);
  position: absolute;
  left: 0;
  transition: all .8s cubic-bezier(.23,1,.32,1);
}
.sec3_2 h5
{
  color: var(--primary-color);
  font-weight: 500;
  letter-spacing: 0.313rem;
  font-family: "Saira Condensed",sans-serif;
  font-size: 1.25rem;
  line-height: 1.75;
  padding-top: 10%
}
.sec3_span::before
{
  content: "";
  width: 0%;
  position: absolute;
  left: 0;
  top: 40%;
  transform: translate(0, -50%);
}
.sec3_span:hover::before
{
  width: 40%;
  height: 3px;
  background: var(--before-color);
  transition: width 2s;
}
.sec3_btn2:hover::before 
{
  width: 100%;
  height: 5%;
  /* text-align: center; */
  margin: 0 auto;
  border-radius: 3%;
  top: 45%;
}
.sec3_btn2:hover 
{
  background-color: var(--white);
  color: var(--primary-color) !important;
}
.sec3_btn2
{
  text-decoration: none;
  color: var(--white);
}
.sec3_sec1
{
  width: 100%;
  height: 444px;
  justify-content: center;
  background-color: var(--white);
}
.sec3_sec1 img
{
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.sec3_sec1:hover .vision_tit, .sec3_main:hover .vision_cont, .sec3_main:hover
{
  background-color: var(--primary-color);
  background-image: url('../src/images/PATTERN.png');
  color: var(--white);
  border-radius: 15px 0px;
}
.sec3_main 
{
  justify-content: center;
}
.vision_tit
{
  text-align: center;
  padding-top: 10%;
  color: var(--primary-color);
  font-weight: 600;
  letter-spacing: 0.313rem;
  font-family: "Saira Condensed",sans-serif;
  font-size: 1rem;
  line-height: 1.75;
}
.vision_img
{
  margin-top: 15%;
  height: 150px;
  width: 100%;
  text-align: center;
  object-fit: cover;
}
.vision_cont
{
  color: var(--grey);
  font-size: 1rem;
  line-height: 1.75;
  font-family: "Roboto",sans-serif;
  font-weight: 400;
  padding: 10%;
  text-align: center;
}
.sec4_im
{
  object-fit: cover;
  max-width: 100%;
  /* height: 100%; */
  /* height: auto; */
}
.img_fluid
{
  height: auto;
}
.sec4
{
  margin-top: 5%;
  /* height: auto; */
   /* margin-bottom: 5%; */
}
.sec4_tit
{
  position: relative;
  text-align: center;
}
.sec4_tit1 h5
{
  color: var(--primary-color);
  font-weight: 500;
  letter-spacing: 0.313rem;
  font-family: "Saira Condensed",sans-serif;
  font-size: 1.25rem;
  line-height: 1.75;
}
.sec4_tit1::before
{
  content: "";
  width: 0%;
  position: absolute;
  left: 46%;
  top: 70%;
  transform: translate(0, -50%);
}
.sec4_tit1:hover::before
{
  width: 8%;
  height: 3px;
  background: var(--before-color);
  transition: width 2s;
}
.sec4_img:hover
{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media screen and (max-width: 1212px) {
  header{
    display: none;
  }
}
@media screen and (min-width:1440px) {
  .cont h2
 {
  font-size: 100px;
  white-space: wrap;
  margin: 0 auto;
 }
}
@media screen and (max-width:768px){
 .navbar
 {
  display: flex;
 }
 .cont h2
 {
  font-size: 60px;
  white-space: wrap;
  margin: 0 auto;
 }
 .row .sec1_2
 {
  flex-direction: column;
  float: center;
  align-items: center;
 }
 .sec1_2
 {
  margin: 0 auto;
 }
 .elem.elem-sec.elem-column.backimg
  {
    width: 100%;
  }
  .sec3
  {
    background-color: var(--white);
  }
  .sec4
  {
    display: none;   
  }
}
@media screen and (max-width:1024px){
 .sec2_icn 
{
  display: none;
}
}
@media screen and (min-width:540px) {
  .sub1
  {
    display: flex;
    justify-content: center;
  }
  
}
@media screen and (max-width:768px) {
  .sub1
  {
    display: block;
    text-align: center;
  }
  .vision_cont
  {
    padding: 0;
  }
  .num
  {
    justify-content: center;
  }
  .num::after
  {
    display: none;
  }
  .sec4_img
  {
    width: 100%;
    overflow: visible;
  }
}
@media screen and (min-width:1400px) {
  .sec1_2
  {
    justify-content: center;
  }
  
}
@media screen and (max-width:1024px) and (min-width:768px){
  .sec1_2
  {
    justify-content: center;
  }
  .cont
  {
    margin: 0 auto;
  }
  .sec4_img
  {
    width: 100%;
    overflow: visible;
  }
}
@media screen and (min-width: 2560px) {
  .vision_cont
  {
    padding: 0;
  }

}