@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');


:root{
  --dark-grey: #333333;
  --white: #fff;
  --black: #000;
  --green:  #7fb401;
  --grey: #666;
  --icon: #f5f5f5;
  --light-grey: #ccc;
  --primary-color: #2c604a;
  --before-color:#f7f052;
  --global-body: #f1f3f5;
  --border-color: #e3e3e3;
  --sec2-color: #fbfafa;
  --font-family: 'Roboto Condensed', sans-serif;
  --font-family1: 'Saira Condensed', sans-serif;
}
*{
  box-sizing: border-box;
  font-family: var(--text);
  padding: 0;
  margin: 0;
}

.gall_im
{
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.gall
{
  margin-top: 5%;
}
.gall_tit
{
  position: relative;
  text-align: center;
}
.gall_tit1 h5
{
  color: var(--primary-color);
  font-weight: 500;
  letter-spacing: 0.313rem;
  font-family: "Saira Condensed",sans-serif;
  font-size: 1.25rem;
  line-height: 1.75;
}
.gall_tit1::before
{
  content: "";
  width: 0%;
  position: absolute;
  left: 46%;
  top: 70%;
  transform: translate(0, -50%);
}
.gall_tit1:hover::before
{
  width: 8%;
  height: 3px;
  background: var(--before-color);
  transition: width 2s;
}
.gall_img:hover
{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.gall_btn
{
  position: relative;
  margin-top: 3%;
  margin-bottom: 10%;
}
.gall_btn1
{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -0%);
}
.gall_btn2
{
  padding: 10px 30px;
  font-size: 1rem;
  display: inline-block;
  vertical-align: top;
  background-color: var(--primary-color);
  color: var(--white);
  letter-spacing: 0.5rem;
  font-weight: 600;
  font-family: var(--font-family1);
  line-height: normal;
  border: none;
  transform: translate(0);
  transition: all .5s ease-in-out;
  border-radius: 0px;
  text-decoration: none;
  color: var(--white);
}
.gall_btn2::before
{
  content: "";
  background-color: var(--before-color);
  width: 3%;
  height: 100%;
  padding: 0;
  z-index: -1;
  top: 0;
  transform: translateY(0, 0);
  position: absolute;
  left: 0;
  transition: all .8s cubic-bezier(.23,1,.32,1);
}
.gall_btn2:hover::before 
{
  width: 100%;
  height: 5%;
  /* text-align: center; */
  margin: 0 auto;
  border-radius: 3%;
  top: 45%;
}
.gall_btn2:hover 
{
  background-color: var(--white);
  color: var(--primary-color) !important;
}