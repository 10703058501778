@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root{
  --dark-grey: #333333;
  --white: #fff;
  --black: #000;
  --green:  #7fb401;
  --grey: #666;
  --icon: #f5f5f5;
  --light-grey: #ccc;
  --primary-color: #2c604a;
  --before-color:#f7f052;
  --global-body: #f1f3f5;
  --border-color: #e3e3e3;
  --sec2-color: #fbfafa;
  --font-family: 'Roboto Condensed', sans-serif;
  --font-family1: 'Saira Condensed', sans-serif;
}
*{
  box-sizing: border-box;
  font-family: var(--text);
  padding: 0;
  margin: 0;
}

.sec6
{
  margin-top: 5%;
  height: 100%;
  background-color: var(--border-color);
}
.alg
{
  padding-left: 3%;
}
.sec6_last
{
  padding: 1.5%;
  text-align: center;
}
.sec6_tit
{
  position: relative;
}
.sec6_tit1 h5
{
  color: var(--primary-color);
  text-shadow: 2px 2px 4px var(--grey);
  font-weight: 500;
  letter-spacing: 0.313rem;
  font-family: "Saira Condensed",sans-serif;
  font-size: 1.25rem; 
  line-height: 1.75;
}
.sec6_ul ul
{
  list-style: none;
}
.sec6_li
{
  text-decoration: none;
  color: var(--primary-color);
}

@media screen and (max-width: 450px) and (min-width: 320px){
 .sec6
 {
  margin-top: 197%;
  background-color: var(--white) !important;
 }
 .sec6_tit
 {
  text-align: center;
 }
 .sec6_ul li
 {
  text-align: center;
 }
}