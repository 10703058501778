@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');


:root{
  --dark-grey: #333333;
  --white: #fff;
  --black: #000;
  --green:  #7fb401;
  --grey: #666;
  --icon: #f5f5f5;
  --light-grey: #ccc;
  --primary-color: #2c604a;
  --before-color:#f7f052;
  --global-body: #f1f3f5;
  --border-color: #e3e3e3;
  --sec2-color: #fbfafa;
  --font-family: 'Roboto Condensed', sans-serif;
  --font-family1: 'Saira Condensed', sans-serif;
}
*{
  box-sizing: border-box;
  font-family: var(--text);
  padding: 0;
  margin: 0;
}
.ourwork
{
  padding: 2%;
}
.cont
{
    color: var(--primary-color);
    text-shadow: 2px 2px 4px var(--grey);
}
.ourwork_cont 
{
  color: var(--grey);
  font-size: 1rem;
  line-height: 1.75;
  font-family: "Roboto",sans-serif;
  font-weight: 400;
  text-align: left;
}
.ourwork_li1
{
  text-align: start;
  display: flex;
}
.ourwork_li1 p
{
  padding-left: 1%;
  line-height: 0.75;
  color: var(--grey);
  font-size: 1rem;
  font-family: "Roboto",sans-serif;
  font-weight: 400;
}
.ourwork_sec1
{
  /* padding-top: 4%; */
  color: var(--grey);
  font-size: 1rem;
  line-height: 1.75;
  font-family: "Roboto",sans-serif;
  font-weight: 400;
  margin: 3%;
}
.ourwork_sec1 ul
{
  list-style: none;
  padding: 0px 0px 0px 0px;
}
.ourwork_btn
{
  position: relative;
}
.ourwork_btn1
{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -0%);
}
.ourwork_btn2
{
  padding: 10px 30px;
  font-size: 1rem;
  display: inline-block;
  vertical-align: top;
  background-color: var(--primary-color);
  color: var(--white);
  letter-spacing: 0.5rem;
  font-weight: 600;
  font-family: var(--font-family1);
  line-height: normal;
  border: none;
  transform: translate(0);
  transition: all .5s ease-in-out;
  border-radius: 0px;
  text-decoration: none;
  color: var(--white);
}
.ourwork_btn2::before
{
  content: "";
  background-color: var(--before-color);
  width: 3%;
  height: 100%;
  padding: 0;
  z-index: -1;
  top: 0;
  transform: translateY(0, 0);
  position: absolute;
  left: 0;
  transition: all .8s cubic-bezier(.23,1,.32,1);
}
.ourwork_btn2:hover::before 
{
  width: 100%;
  height: 5%;
  /* text-align: center; */
  margin: 0 auto;
  border-radius: 3%;
  top: 45%;
}
.ourwork_btn2:hover 
{
  background-color: var(--white);
  color: var(--primary-color) !important;
}

.corpo
{
  padding-bottom: 2%;
}